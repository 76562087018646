<template>
  <main class="contacts-page page">
    <BreadcrumbsComponent title="Контакты" />
    <div class="container-padding">
      <div class="contacts-page__inner">
        <div v-if="department" class="contacts-page__content">
          <div class="contacts-page__info">
            <span v-if="department.address" class="contacts-page__info-item">
              <b>Адрес:</b>{{ department.address }}
            </span>
            <a v-if="department.phone" :href="`tel:${department.phone}`" class="contacts-page__info-item">
              <b>Телефон:</b>{{ department.phone }}
            </a>
            <a v-if="department.fax" :href="`tel:${department.fax}`" class="contacts-page__info-item">
              <b>Факс:</b>{{ department.fax }}
            </a>
            <a v-if="department.email" :href="`mailto:${department.email}`" class="contacts-page__info-item">
              <b>Электронная почта:</b>{{ department.email }}
            </a>
            <span v-if="department.schedule" class="contacts-page__info-item">
              <b>Режим работы:</b>{{ department.schedule }}
            </span>
            <span v-if="info.weekends" class="contacts-page__info-item">
              <b>Выходные дни:</b>{{ info.weekends }}
            </span>
            <div class="contacts-page__socials" v-if="department.socials">
              <a :href="social.link" target="_blank" v-for="(social, i) in department.socials" :key="i">
                <IconComponent category="default" :name="social.type.code | socialIconName" />
              </a>
            </div>
          </div>
          <div v-if="department.address" class="contacts-page__map">
            <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
              <ymap-marker marker-id="0" :coords="coords" />
            </yandex-map>
          </div>
        </div>
        <div v-if="department" class="contacts-page__footer">
          <div
            v-if="
              department.government_reference_information &&
              department.government_reference_information.length
            "
            class="contacts-page__footer-inner"
          >
            <h4 class="contacts-page__footer-title">Справочная информация</h4>
            <div
              v-for="(item, i) in department.government_reference_information"
              :key="i"
              class="contacts-page__footer-item"
            >
              <p v-if="item.title">{{ item.title }}</p>
              <a v-if="item.phone" :href="`tel:${item.phone}`">
                <span>{{ item.phone }}</span>
              </a>
            </div>
          </div>
          <div
            v-if="department.government_hot_lines && department.government_hot_lines.length"
            class="contacts-page__footer-inner"
          >
            <h4 class="contacts-page__footer-title">Горячие линии</h4>
            <div
              v-for="(item, i) in department.government_hot_lines"
              :key="i"
              class="contacts-page__footer-item"
            >
              <p v-if="item.title">{{ item.title }}</p>
              <a v-if="item.phone" :href="`tel:${item.phone}`">
                <span>{{ item.phone }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "ContactsPage",
  data() {
    return {
      mapReady: false,
      coords: [0, 0],
      debounceInterval: null,
      settings: {
        apiKey: "10a106bb-ea2e-4832-a627-34e8e3ccc9cb",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      info: {
        weekends: "суббота, воскресенье",
      },
    };
  },
  computed: {
    department() {
      return this.$store.state.department;
    },
  },
  methods: {
    initMap() {
      loadYmap(this.settings).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(this.department.address).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
            this.mapReady = true;
          });
        });
      });
    },
  },
  mounted() {
    this.initMap();
    this.debounceInterval = setInterval(() => {
      window.addEventListener("resize", () => {
        this.mapReady = false;
        this.$nextTick(() => {
          this.initMap();
        });
      });
    }, 500);
  },
  components: {
    BreadcrumbsComponent,
    IconComponent,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
.contacts-page {

  &__inner {
    display grid
    grid-gap 40px
    +below(425px) {
      grid-gap 20px
    }
  }

  &__content {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 40px
    background var(--white)
    +below(1255px) {
      grid-template-columns 1fr
      gap 30px
    }
  }

  &__info {
    display flex
    flex-direction column
    align-items flex-start
    gap 15px
    padding 30px
    +below(1255px) {
      padding 30px 30px 0 30px
    }
    +below(425px) {
      padding 15px 15px 0 15px
    }
  }

  &__info-item {
    display flex
    flex-wrap wrap
    gap 5px
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      background var(--white)
      border 1px solid var(--gray)
      width 40px
      height 40px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 20x
        max-height: 20px

        svg path {
          fill var(--main)
        }
      }
    }
  }

  &__footer {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 40px
    +below(700px) {
      grid-template-columns 1fr
    }
    +below(425px) {
      grid-gap 20px
    }
  }

  &__footer-inner {
    display grid
    grid-gap 5px
  }

  &__footer-title {
    border-bottom 1px solid var(--gray-dark)
    padding 15px 0
    +below(425px) {
      font-size 1.25rem
    }
  }

  &__footer-item {
    display grid
    grid-gap 5px
    +below(425px) {
      p, span {
        font-size .875rem
      }
    }
  }

  &__map {
    height 400px
    overflow hidden
    +below(425px) {
      height 300px
    }

    > * {
      height 100%
    }
  }
}
</style>
